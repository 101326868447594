






import { Component, Vue } from "vue-property-decorator";
import Comercios from "@/components/Beneficios/Comercios.vue";

@Component({
  components: {
    Comercios
  }
})
export default class ComerciosView extends Vue {}
