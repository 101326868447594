




































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";
import { Debounce } from "vue-debounce-decorator";

interface ApiData {
  tipo: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  rut: string;
  fechaNacimiento: string;
  estadoCivil: number;
  nacionalidad: number;
  sexo: number;
  prevision: number;
  direccion: {
    calle: string;
    calle_id: number;
    comuna: string;
    comuna_id: number;
    numero: string;
    bloque?: string;
    depto?: string;
    referencia?: string;
  };
  email: string;
  telefono: string;
  ocupacion?: string;
  profesion?: string;
  acreditacion: string;
  acreditacion_ext: string;
  carnet: string;
  carnet_ext: string;
  observacion: string;
  mensaje: string;
  titular: string;
  tipoVecino: number;
}

interface SelectOption {
  value: number | any;
  label: string;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    TerminosYCondicionesComponent
  }
})
export default class ApplicationForm extends Mixins(FormValidator) {
  @Prop({ required: true })
  private data!: ApiData;
  @Prop({ required: true })
  private rut!: string;
  private obs: string = "";
  private terms: boolean = false;
  private switch1: boolean = true;
  private privitionsList: any[] = [];
  private streetsList: any[] = [];
  private comunaList: any[] = [];
  private paisesList: any[] = [];
  private comuna = null as number | null;
  private bTerminos = false;
  private terminosFooter = false;
  private customModel = "no";
  private formattedFecha = "";
  private titular: string = "si";
  private modal1: boolean = true;
  private modal2: boolean = true;
  private modal3: boolean = true;

  private loading: boolean = false;

  private dialogCI = false;
  private dialogACT = false;
  private dialogACC = false;
  private dialogACH = false;
  private dialogACHM = false;
  private dialogACHN = false;
  private dialogACAH = false;
  private dialogACO = false;
  private dialogLS = false;
  private dialogAddress = false;
  private dialogTerms = false;

  private downloadCI = false;
  private downloadAC = false;
  private invalidFile = false;

  private typeOptions: Array<any> = [];
  private streetsListOptions: Array<any> = [];
  private aPrevisionOptions: Array<any> = [];

  private genderOptions: SelectOption[] = [];

  private paisesOptions: SelectOption[] = [];

  private cStateOptions: SelectOption[] = [];

  private ComunaData: Tipos = {
    value: null,
    label: ""
  };
  private dataParentesco: Tipos = {
    value: null,
    label: " "
  };
  private dataEstadoCivil: Tipos = {
    value: null,
    label: " "
  };

  private dataNation: Tipos = {
    value: null,
    label: " "
  };

  private dataSexo: Tipos = {
    value: null,
    label: " "
  };

  private dataPrevision: Tipos = {
    value: null,
    label: " "
  };

  private dataCalle: Tipos = {
    value: null,
    label: " "
  };

  private selected = false;
  private dataForm = {
    parentesco: null as number | null,
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    rut: "",
    fechaNacimiento: null as Date | null,
    estadoCivil: null as number | null,
    sexo: null as number | null,
    nacionalidad: null as number | null,
    prevision: null as number | null,
    calle: "",
    calle_id: null as number | null,
    comuna: "",
    comuna_id: null as number | null,
    referencia: "",
    numero: "",
    bloque: "" as string | undefined,
    depto: "" as string | undefined,
    email: "",
    telefono: "",
    profesion: "" as string | undefined,
    ocupacion: "" as string | undefined,
    tarjeta: false,
    acreditacion_ext: "",
    carnet_ext: ""
  };
  private carnet: File | null = null;
  private acreditacion: File | null = null;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  get age() {
    const today = new Date();
    const date = this.dataForm.fechaNacimiento;
    if (date) {
      const age = (today.getTime() - date.getTime()) / (1000 * 3600 * 24 * 365);
      return age;
    }
    return 0;
  }

  get isNotTitular() {
    if (this.dataForm.parentesco === 1) {
      return false;
    }
    return true;
  }

  get isMinorType() {
    if (this.dataForm.parentesco === 1) {
      return true;
    } else {
      return false;
    }
  }

  get isMinorType_hijo() {
    if (this.dataForm.parentesco === 3) {
      return true;
    } else {
      return false;
    }
  }

  get isMinorType_conyuge() {
    if (this.dataForm.parentesco === 2) {
      return true;
    } else {
      return false;
    }
  }

  get validateNumber() {
    const street = this.streetsList.find(
      (el: any) => el.value === this.dataForm.calle_id
    );
    const validation = "required";
    return validation;
  }

  get validateEmail() {
    if (this.age < 18) {
      return "";
    } else {
      return "required|email";
    }
  }

  get validatePhone() {
    if (this.age < 18) {
      return "";
    } else {
      return "required|length:9";
    }
  }

  get validateCarnet() {
    if (this.age < 18) {
      return "";
    } else {
      return "required";
    }
  }

  get validForm() {
    if (this.titular === "no" && this.dataForm.parentesco !== 1) {
      return false;
    } else if (this.age < 18 && this.isMinorType) {
      return false;
    } else if (this.age < 18 && this.isMinorType_conyuge) {
      return false;
    } else if (this.age >= 25 && this.isMinorType_hijo) {
      return false;
    } else if (this.invalidFile) {
      return false;
    }
    return true;
  }

  @Watch("formattedFecha")
  @Debounce(1000)
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      this.dataForm.fechaNacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fechaNacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fechaNacimiento = new Date(birthDate);
    }
  }

  @Watch("dataParentesco")
  private parentescoWatcher(newValue: any) {
    this.selected = true;
    this.dataForm.parentesco = newValue.value;
  }

  @Watch("dataEstadoCivil")
  private estadocivilWatcher(newValue: any) {
    this.dataForm.estadoCivil = newValue.value;
  }

  @Watch("dataNation")
  private nationWatcher(newValue: any) {
    this.dataForm.nacionalidad = newValue.value;
  }

  @Watch("dataSexo")
  private sexoWatcher(newValue: any) {
    this.dataForm.sexo = newValue.value;
  }

  @Watch("dataPrevision")
  private previsionWatcher(newValue: any) {
    this.dataForm.prevision = newValue.value;
  }

  @Watch("dataCalle")
  private calleWatcher(newValue: any) {
    this.dataForm.calle_id = newValue.value;
  }

  private mounted() {
    this.obs = this.data.observacion;
    this.titular = this.data.titular;
    this.$axios("prevision").then(res => {
      this.dataPrevision = {
        value: res.data[0].id,
        label: res.data[0].nombre
      };
      res.data.forEach((prevition: any) => {
        this.privitionsList.push({
          value: prevition.id,
          label: prevition.nombre
        });
        if (prevition.id == this.dataForm.prevision) {
          this.dataPrevision = {
            value: prevition.id,
            label: prevition.nombre
          };
        }
      });
    });

    this.$axios("parentesco").then(res => {
      res.data.forEach((entry: any) => {
        this.typeOptions.push({
          value: entry.id,
          label: this.$t(`meta.type[${entry.id - 1}]`).toString()
        });
        if (entry.id == this.dataForm.parentesco) {
          this.dataParentesco.value = entry.id;
          this.dataParentesco.label = entry.nombre;
        }
      });

      this.typeOptions.sort((a, b) => (a.label <= b.label ? -1 : 1));
      if (this.dataForm.parentesco === 1 || this.titular === "no") {
        this.typeOptions = this.typeOptions.filter(
          (element: any) => element.value === 1
        );
      } else {
        this.typeOptions = this.typeOptions.filter(
          (element: any) => element.value !== 1
        );
      }
    });
    this.$axios("pais").then(res => {
      this.dataNation = {
        value: res.data[44].id,
        label: res.data[44].nombre_es
      };
      if (this.$store.state.user.lenguage === "es") {
        this.paisesList.push({
          value: res.data[44].id,
          label: res.data[44].nombre_es
        });
      } else if (this.$store.state.user.lenguage === "en") {
        this.paisesList.push({
          value: res.data[44].id,
          label: res.data[44].nombre_en
        });
      }
      res.data.forEach((country: any, index: number) => {
        if (res.data.length - 1 !== index && index !== 44) {
          if (this.$store.state.user.lenguage === "es") {
            this.paisesList.push({
              value: country.id,
              label: country.nombre_es
            });
          } else if (this.$store.state.user.lenguage === "en") {
            this.paisesList.push({
              value: country.id,
              label: country.nombre_en
            });
          }
        }
        if (country.id == this.dataForm.nacionalidad) {
          this.dataNation = {
            value: country.id,
            label: country.nombre_es
          };
        }
      });
      if (this.dataForm.nacionalidad == 47) {
        this.dataNation = {
          value: this.paisesList[0].value,
          label: this.paisesList[0].label
        };
      }
    });
    this.genderOptions.push(
      { value: 1, label: this.$t("meta.sex.male").toString() },
      { value: 0, label: this.$t("meta.sex.female").toString() }
    );
    this.cStateOptions.push(
      { value: 0, label: this.$t("meta.status.single").toString() },
      { value: 1, label: this.$t("meta.status.married").toString() },
      { value: 2, label: this.$t("meta.status.union").toString() },
      { value: 3, label: this.$t("meta.status.divorced").toString() },
      { value: 4, label: this.$t("meta.status.null").toString() },
      { value: 5, label: this.$t("meta.status.widow").toString() }
    );
    this.fillForm(this.data);
  }

  private fillForm(data: ApiData) {
    this.dataForm.rut = this.rut.toUpperCase();
    this.dataForm.calle_id = data.direccion.calle_id;
    this.dataForm.calle = data.direccion.calle;
    this.dataForm.numero = data.direccion.numero;
    this.dataForm.bloque = data.direccion.bloque;
    this.dataForm.depto = data.direccion.depto;
    this.dataForm.fechaNacimiento = null;
    this.dataForm.nombre = data.nombre ? data.nombre.toUpperCase() : "";
    this.dataForm.apellidoPaterno = data.apellidoPaterno
      ? data.apellidoPaterno.toUpperCase()
      : "";
    this.dataForm.apellidoMaterno = data.apellidoMaterno
      ? data.apellidoMaterno.toUpperCase()
      : "";
    if (data.mensaje == "vencido" || data.mensaje == "para renovar") {
      this.dataForm.email = "";
      this.dataForm.telefono = "";
    } else {
      this.dataForm.email = data.email ? data.email : "";
      this.dataForm.telefono = data.telefono ? data.telefono : "";
    }
    if (data.mensaje !== "nuevo") {
      this.dataForm.parentesco = data.tipo;
      let formatFechaApi =
        data.fechaNacimiento.substring(8, 10) +
        "-" +
        data.fechaNacimiento.substring(5, 7) +
        "-" +
        data.fechaNacimiento.substring(0, 4) +
        " 12:00:00";
      // En watch se actualiza el dataForm.fechaNacimiento
      this.formattedFecha = formatFechaApi;
      if (data.estadoCivil === 6) {
        this.dataForm.estadoCivil = 0;
      } else {
        this.dataForm.estadoCivil = data.estadoCivil;
      }
      this.dataForm.sexo = data.sexo;
      this.dataForm.nacionalidad =
        data.nacionalidad === 256 ? null : data.nacionalidad;
      this.dataForm.prevision = data.prevision;
      this.dataForm.ocupacion = data.ocupacion;
      this.dataForm.profesion = data.profesion;
      if (data.mensaje == "vencido" || data.mensaje == "para renovar") {
        this.dataForm.acreditacion_ext = "";
        this.dataForm.carnet_ext = "";
        this.carnet = null;
        this.acreditacion = null;
        this.downloadCI = false;
        this.downloadAC = false;
      } else {
        this.downloadCI = data.carnet ? true : false;
        this.downloadAC = data.acreditacion ? true : false;
        this.dataForm.acreditacion_ext = data.acreditacion_ext;
        this.dataForm.carnet_ext = data.carnet_ext;
      }
      this.dataForm.referencia = data.direccion.referencia
        ? data.direccion.referencia
        : "";
    }

    for (let j = 0; j < this.cStateOptions.length; j++) {
      if (this.cStateOptions[j].value == this.dataForm.estadoCivil) {
        this.dataEstadoCivil = this.cStateOptions[j];
        break;
      }
    }

    for (let j = 0; j < this.genderOptions.length; j++) {
      if (this.genderOptions[j].value == this.dataForm.sexo) {
        this.dataSexo = this.genderOptions[j];
        break;
      }
    }

    this.calles();
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.ARCHIVO_MAXIMO").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
        if (target.name === "acreditacion") {
          this.acreditacion = target.files[0];
        } else if (target.name === "carnet") {
          this.carnet = target.files[0];
        }
        this.invalidFile = false;
      }
    }
  }

  private forceUpper(value: any) {
    if (!value) {
      return "";
    }
    value = value.toString();
    return value.toUpperCase();
  }

  private calles() {
    this.$axios("streets").then(res => {
      res.data.forEach((street: any) => {
        this.streetsList.push({
          value: street.id,
          label: street.nombre,
          min: street.min,
          max: street.max
        });
        
        if (street.id == this.dataForm.calle_id) {
          this.dataCalle = {
            value: street.id,
            label: street.nombre
          };
        }
      });
      
      if (this.dataForm.calle_id === -1 && this.dataForm.calle) {
          this.streetsList.push({
            value: this.dataForm.calle_id,
            label: this.dataForm.calle,
          });
          this.dataCalle = {
            value: this.dataForm.calle_id,
            label: this.dataForm.calle
          };
        } else if (this.dataForm.calle_id === -1) {
          this.dataForm.calle_id = null;
        }
    });
  }

  private showModal() {
    if (this.dataForm.parentesco === 2) {
      this.dialogACC = true;
    } else if (this.dataForm.parentesco === 3 && this.age < 25) {
      this.dialogACH = true;
    } else if (this.dataForm.parentesco === 3 && this.age >= 25) {
      this.dialogACHM = true;
    } else if (
      this.dataForm.parentesco === 20 ||
      this.dataForm.parentesco === 21
    ) {
      this.dialogACHN = true;
    } else if (this.dataForm.parentesco === 19) {
      this.dialogACAH = true;
    } else if (this.dataForm.parentesco === 1) {
      this.dialogACT = true;
    } else {
      this.dialogACO = true;
    }
  }

  private submit() {
    if (
      this.dataForm.rut == "" ||
      this.validateRut(this.dataForm.rut) == false
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.parentesco == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_PARENTESCO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      this.dataForm.fechaNacimiento == null ||
      !this.checkFecha(this.dataForm.fechaNacimiento)
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.validFecha(this.dataForm.fechaNacimiento)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fechaNacimiento.getFullYear() < 1890) {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.form").toString(),
            text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
          return;
        }
      }
    }
    if (this.age < 18 && this.dataForm.parentesco == 1) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.EDAD_SOLICITUD_TITULAR").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.age < 18 && this.dataForm.parentesco == 2) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.EDAD_SOLICITUD_CONYUGE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.age > 25 && this.dataForm.parentesco == 3) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.EDAD_SOLICITUD_HIJO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.nombre == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_NAME").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.dataForm.nombre = this.forceUpper(this.dataForm.nombre);
    if (this.dataForm.apellidoPaterno == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_APELLIDO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.dataForm.apellidoPaterno = this.forceUpper(
      this.dataForm.apellidoPaterno
    );
    if (this.dataForm.email == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (!this.validEmail(this.dataForm.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.dataForm.email = this.forceUpper(this.dataForm.email);
    if (this.dataForm.telefono == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.telefono.length < 9) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.sexo == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_GENERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.nacionalidad == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_NACIONALIDAD").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.estadoCivil == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_ESTADO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.prevision == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_PREVISION").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.calle_id == null ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_CALLE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.dataForm.numero == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.NO_NUMERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.data.mensaje == "correccion") {
      if (this.carnet == null && this.downloadCI == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_CARNET").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      } else if (this.acreditacion == null && this.downloadAC == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_ACREDITACION").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }

    if (this.data.mensaje == "vencido") {
      if (this.carnet == null && this.downloadCI == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_CARNET").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      } else if (this.acreditacion == null && this.downloadAC == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_ACREDITACION").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }

    if (this.data.mensaje == "para renovar") {
      if (this.carnet == null && this.downloadCI == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_CARNET").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      } else if (this.acreditacion == null && this.downloadAC == false) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_ACREDITACION").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }
    

    if (this.data.mensaje == "nuevo") {
      if (this.carnet == null) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_CARNET").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }

      if (this.acreditacion == null) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.error.NO_ACREDITACION").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
    }

    if (this.customModel == "no" || !this.customModel) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.ERROR_TERMINOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.loading = true;
    this.$q.loading.show({
      delay: 100 // ms
    });
    const streetName = this.streetsList.find(
      street => street.value === this.dataForm.calle_id
    );
    this.dataForm.calle = streetName ? streetName.label : "";
    const data = new FormData();
    if (this.carnet) {
      const ciFile = this.carnet as Blob;
      data.append("carnet", ciFile);
    } else {
      const ciFile = new File(["file"], "Carnet") as Blob;
      data.append("carnet", ciFile);
    }
    if (this.acreditacion) {
      const acFile = this.acreditacion as Blob;
      data.append("acreditacion", acFile);
    } else {
      const acFile = new File(["file"], "Acreditación") as Blob;
      data.append("acreditacion", acFile);
    }
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }
    if (!this.dataForm.apellidoMaterno) {
      data.append("apellidoMaterno", "");
    }

    this.$axios
      .post("vecino", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(result => {
        this.loading = false;
        this.$q.loading.hide();
        this.$swal({
          icon: "success",
          title: this.$t("notifications.titles.form").toString(),
          text: this.$t("notifications.success.application").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });

        this.$router.push({ name: "ApplicationsList" });
      })
      .catch(error => {
        this.$q.loading.hide();
        const message = error.response.data.message;
        if (message === "FILEA_ERROR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.form").toString(),
            text: this.$t("notifications.error.FILEA_ERROR").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "FILEC_ERROR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.form").toString(),
            text: this.$t("notifications.error.FILEC_ERROR").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.form").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
        this.loading = false;
      });
  }

  private updateDate(value: any) {
    this.dataForm.fechaNacimiento = new Date(
      value.replace(/-/g, "/") + " 00:00:00"
    );
  }

  private onCloseTerminos(info) {
    if (info.footer == true) {
      if (info.response == true) {
        this.customModel = "si";
        this.bTerminos = false;
      } else if (info.response == false) {
        this.customModel = "no";
        this.bTerminos = false;
      } else {
        this.bTerminos = false;
      }
    } else {
      this.bTerminos = false;
    }
  }

  private filterFn(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.streetsListOptions = this.streetsList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private filterFnPrevisiones(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.aPrevisionOptions = this.privitionsList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private filterFnPaises(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.paisesOptions = this.paisesList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private mostrarTerminos(footer) {
    this.terminosFooter = footer;
    this.bTerminos = true;
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private validFecha(date: any) {
    if (date.getFullYear() < 1900) {
      return true;
    } else {
      return false;
    }
  }

  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
