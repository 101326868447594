












































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

interface Action {
  title: string;
  to: string;
  parameter: any;
  type: string;
}

interface Entry {
  id: string;
  rut: string;
  rut_label: string;
  nombre: string;
  apellidoMaterno: string;
  apellidoPaterno: string;
  nombre_label: string;
  codigo?: string;
  email: string;
  estado: string;
  estado_label: string;
  fecha: Date;
  fecha_label: string;
  qr?: any;
  action?: Action[];
  tipoVecino: number;
  parentesco: number;
  parentesco_label: string;
}

@Component({
  filters: {
    capitalize(value: string) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class ApplicationsListView extends Vue {
  private headers: any[] = [];

  private loading = true;

  private entries: Entry[] = [];

  private horizontalPercentageSolicitudes = 0;

  private cardImage = new Image();

  private vecinoTipo: number = 0;

  private buttonsAction(entry: Entry) {
    const buttons: Action[] = [];
    if (entry.estado === "corrección") {
      buttons.push({
        to: "ApplicationEdit",
        parameter: entry.rut,
        title: this.$t("buttons.edit").toString(),
        type: "warning"
      });
    }
    if (entry.estado === "vencido" || entry.estado === "para renovar") {
      buttons.push({
        to: "ApplicationEdit",
        parameter: entry.rut,
        title: this.$t("buttons.renew").toString(),
        type: "negative"
      });
    }
    if (
      entry.estado === "entregada" ||
      entry.estado === "vigente" ||
      entry.estado === "emitida" ||
      entry.estado === "para entrega" ||
      entry.estado === "para renovar" ||
      (entry.estado === "tarjeta vigente" && entry.qr)
    ) {
      buttons.push({
        to: "ApplicationDetails",
        parameter: entry.id,
        title: this.$t("buttons.profile").toString(),
        type: "primary"
      });
    }
    if (entry.estado === "sin tarjeta") {
      buttons.push({
        to: "ApplicationEdit",
        parameter: entry.rut,
        title: this.$t("buttons.get_card").toString(),
        type: "primary"
      });
    }
    return buttons;
  }

  private actionClick(destination: string, parameter: any) {
    if (destination == "ApplicationEdit") {
      this.$store.commit("SET_APPLICATION_RUT", parameter);
      this.$router.push({ name: "ApplicationEdit" }).catch(err => {});
    } else if (destination == "ApplicationDetails") {
      this.$router
        .push({ name: "ApplicationDetails", params: { id: parameter } })
        .catch(err => {});
    }
  }
  private createClick() {
    this.$store.commit("UNSET_APPLICATION_RUT");
    if (this.$router.currentRoute.name == "solicitud") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "solicitud" }).catch(err => {});
    }
  }

  private statusColor(entry: Entry) {
    let config = "text-center ";
    if (
      entry.estado === "entregada" ||
      entry.estado === "vigente" ||
      entry.estado === "tarjeta vigente"
    ) {
      config += "text-positive";
    } else if (
      entry.estado === "en revisión" ||
      entry.estado === "corrección" ||
      entry.estado === "por pagar" ||
      entry.estado === "en renovación"
    ) {
      config += "text-warning";
    } else if (
      entry.estado === "pagado" ||
      entry.estado === "para entrega" ||
      entry.estado === "emitida"
    ) {
      config += "text-primary";
    } else {
      config += "text-negative";
    }
    return config;
  }

  private statusBgColor(entry: Entry) {
    let config = " ";
    if (
      entry.estado === "entregada" ||
      entry.estado === "vigente" ||
      entry.estado === "tarjeta vigente"
    ) {
      config += "bg-positive";
    } else if (
      entry.estado === "en revisión" ||
      entry.estado === "corrección" ||
      entry.estado === "por pagar" ||
      entry.estado === "en renovación"
    ) {
      config += "bg-warning";
    } else if (
      entry.estado === "pagado" ||
      entry.estado === "para entrega" ||
      entry.estado === "emitida"
    ) {
      config += "bg-primary";
    } else {
      config += "bg-negative";
    }
    return config;
  }

  private fullName(entry: Entry) {
    return `${entry.nombre} ${entry.apellidoPaterno} ${entry.apellidoMaterno}`.toUpperCase();
  }

  private parseRUT(entry: string) {
    return entry.toUpperCase();
  }

  private parseDate(entry: Entry) {
    if (entry.fecha) {
      const date = new Date(entry.fecha);
      const locale = this.$store.state.user.lenguage;
      return date.toLocaleDateString("es-CL");
    }
    return "-";
  }

  private getButtonCol(item) {
    if (item.action.length == 1) {
      if (
        item.estado === "entregada" ||
        item.estado === "en renovación" ||
        item.estado === "tarjeta vigente"
      ) {
        return "col-6";
      } else {
        return "col-12";
      }
    } else {
      return "col-6";
    }
  }

  private printImage(entry: Entry) {
    let formattedRut = entry.rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    formattedRut = formattedRut + "-" + entry.rut.split("-")[1];
    formattedRut = formattedRut.toUpperCase();
    const canvas = document.getElementById("myCanvas") as any;
    const localCardImage = new Image();
    localCardImage.src = require("@/assets/tarjeta.png");
    if (canvas && entry.qr.complete) {
      canvas.width = 750;
      canvas.height = 1330;
      const ctx = canvas.getContext("2d");
      ctx.font = "bold 35px Verdana";
      ctx.fillStyle = "#fff";
      ctx.drawImage(localCardImage, 0, 0);
      ctx.textAlign = "center";
      ctx.fillText(
        entry.nombre.toUpperCase() + " " + entry.apellidoPaterno.toUpperCase(),
        canvas.width / 2 - 5,
        910
      );
      ctx.fillText(formattedRut, canvas.width / 2 - 5, 950);
      ctx.drawImage(
        entry.qr,
        0,
        0,
        entry.qr.width,
        entry.qr.height,
        canvas.width / 4 - 25,
        394,
        entry.qr.width * 1.3,
        entry.qr.height * 1.3
      );
      ctx.font = "bold 30px Verdana";
      ctx.fillStyle = "#fff";
      if (entry.codigo) {
        ctx.fillText(
          entry.codigo,
          canvas.width / 2 - 20,
          422 + (entry.qr.height * 0.75) / 2 + 330
        );
      } else {
        ctx.fillText(
          "1234567891",
          canvas.width / 2 - 20,
          422 + (entry.qr.height * 0.75) / 2 + 330
        );
      }
      const element = document.createElement("a");
      element.style.display = "none";
      element.setAttribute("href", canvas.toDataURL("image/jpeg"));
      element.setAttribute("download", "tarjeta.png");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  private parseStatus(status: string) {
    if (status === "bloqueado") {
      return this.$t("status.blocked").toString();
    } else if (status === "entregada") {
      return this.$t("status.delivered").toString();
    } else if (status === "en revisión") {
      return this.$t("status.review").toString();
    } else if (status === "corrección") {
      return this.$t("status.correction").toString();
    } else if (status === "anulado") {
      return this.$t("status.annulled").toString();
    } else if (status === "vencido") {
      return this.$t("status.expired").toString();
    } else if (status === "para renovar") {
      return this.$t("status.renew").toString();
    } else if (status === "vigente") {
      return this.$t("status.valid").toString();
    } else if (status === "no vigente") {
      return this.$t("status.invalid").toString();
    } else if (status === "tarjeta anulada") {
      return this.$t("status.c_annuled").toString();
    } else if (status === "tarjeta bloqueada") {
      return this.$t("status.c_blocked").toString();
    } else if (status === "sin tarjeta") {
      return this.$t("status.no_card").toString();
    } else if (status === "tarjeta vigente") {
      return this.$t("status.physic_card").toString();
    }
    return "";
  }

  private parseType(type: string, userType: string) {
    const nUserType = parseInt(userType, 10);
    if (nUserType !== 1) {
      if (nUserType === 4) {
        return this.$t("meta.neighbor_types.worker").toString();
      } else if (nUserType === 3) {
        return this.$t("meta.neighbor_types.student").toString();
      } else {
        return "-";
      }
    } else {
      const index = parseInt(type, 10) - 1;
      return this.$t(`meta.type[${index}]`).toString();
    }
  }

  private mounted() {
    this.headers.push(
      {
        name: "rut",
        label: "RUT",
        align: "left",
        field: "rut_label"
      },
      {
        name: "nombre",
        align: "center",
        label: this.$t("fields.full_name"),
        field: "nombre_label"
      },
      {
        name: "parentesco",
        label: this.$t("fields.type"),
        field: "parentesco_label"
      },
      {
        name: "fecha",
        label: this.$t("fields.application_date"),
        field: "fecha_label"
      },
      {
        name: "estado",
        label: this.$t("fields.app_status"),
        field: "estado_label"
      },
      {
        name: "options",
        label: this.$t("fields.options")
      }
    );
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios("vecinos/list")
      .then(res => {
        this.entries = res.data;
        this.$store.dispatch("GET_PERMIT", res.data);
        if (this.entries.length > 0) {
          let isFloat = false;
          this.entries.forEach(entry => {
            entry.action = this.buttonsAction(entry);
            this.vecinoTipo = entry.tipoVecino;
            if (entry.qr) {
              const src = entry.qr;
              entry.qr = new Image();
              entry.qr.src = `data: ${src}`;
            }
            if (entry.tipoVecino !== 1) {
              isFloat = true;
            }
            entry.rut_label = this.parseRUT(entry.rut);
            entry.nombre_label = this.fullName(entry);
            entry.estado_label = this.parseStatus(entry.estado);
            entry.fecha_label = this.parseDate(entry);
            entry.parentesco_label = this.parseType(
              entry.parentesco.toString(),
              entry.tipoVecino.toString()
            );
          });
          if (isFloat) {
            this.cardImage.src = require("@/assets/tarjeta.png");
            this.headers = [];
            this.headers.push(
              {
                name: "rut",
                label: "RUT",
                align: "left",
                field: "rut_label"
              },
              {
                name: "nombre",
                align: "center",
                label: this.$t("fields.full_name"),
                field: "nombre_label"
              },
              {
                name: "parentesco",
                label: this.$t("fields.type"),
                field: "parentesco_label"
              },
              {
                name: "fecha",
                label: this.$t("fields.application_date"),
                field: "fecha_label"
              },
              {
                name: "estado",
                label: this.$t("fields.app_status"),
                field: "estado_label"
              },
              {
                name: "options",
                label: this.$t("fields.options")
              }
            );
          } else {
            this.cardImage.src = require("@/assets/tarjeta.png");
          }
          this.loading = false;
        } else {
          this.$router.push({ name: "solicitud" }).catch(() => {});
        }
      })
      .catch(() => {
        this.$store.dispatch("GET_PERMIT", []);
        this.entries = [];
        this.$router.push({ name: "solicitud" }).catch(() => {});
        this.loading = false;
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private scroll(scroll, direccion) {
    var size = (this.$refs[scroll] as any).scrollSize;
    var position = (this.$refs[scroll] as any).scrollPosition;

    if (scroll == "scrollSolicitudes") {
      if (direccion == "derecha") {
        if (this.horizontalPercentageSolicitudes >= 1) {
          (this.$refs[scroll] as any).setScrollPosition(0, 300);
        } else {
          (this.$refs[scroll] as any).setScrollPosition(position + 302, 300);
        }
      } else {
        if (position - 302 < 0) {
          if (position > 0) {
            (this.$refs[scroll] as any).setScrollPosition(0, 300);
          } else {
            (this.$refs[scroll] as any).setScrollPosition(size, 300);
          }
        } else {
          (this.$refs[scroll] as any).setScrollPosition(position - 302, 300);
        }
      }
    }
  }

  private scrollAreaSolicitudes(info) {
    this.horizontalPercentageSolicitudes = info.horizontalPercentage;
  }

  private parseRut(rut: string) {
    let formated_rut = rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .trim();
    formated_rut = formated_rut + "-" + rut.split("-")[1];
    return formated_rut;
  }

  get isdireccion() {
    if (this.$store.state.user.direccion.length !== 0) {
      return true;
    }
    return false;
  }
}
