






import { Component, Vue } from "vue-property-decorator";
import Convenios from "@/components/Beneficios/Convenios.vue";

@Component({
  components: {
    Convenios
  }
})
export default class ConveniosView extends Vue {}
