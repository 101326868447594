






































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import DataCard from "@/components/Common/DataCard.vue";

interface SelectOption {
  value: number;
  text: string;
}

@Component({
  components: {
    DataCard
  },
  filters: {
    capitalize(value: string) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class AssociateCard extends Mixins(FormValidator) {
  @Prop({ required: true })
  private id_vecino!: any;
  @Prop({ required: true })
  private idTarjeta!: any;
  @Prop({ required: true })
  private editarTarjeta!: boolean;
  private dataTarjeta = {
    number: "",
    numberRepeat: ""
  };

  private mounted() {}

  private vincularTarjeta() {
    if (this.idTarjeta != -1 && this.editarTarjeta) {
      if (
        this.dataTarjeta.number == "" ||
        this.dataTarjeta.number.length > 10
      ) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.associate_card").toString(),
          text: this.$t("notifications.error.INVALID_CARD_NUMBER").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
      if (this.dataTarjeta.number != this.dataTarjeta.numberRepeat) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.associate_card").toString(),
          text: this.$t("notifications.error.NUMBER_MATCH").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        return;
      }
      this.$q.loading.show({
        delay: 100 // ms
      });
      this.$axios
        .post("vincular/tarjeta", {
          id_vecino: this.id_vecino,
          tarjeta: this.dataTarjeta.number
        })
        .then(res => {
          this.$q.loading.hide();
          this.$swal({
            icon: "success",
            title: this.$t("notifications.success.title").toString(),
            text: this.$t("notifications.success.success_title").toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            allowOutsideClick: false,
            allowEscapeKey: false,
            didDestroy: () => {
              this.$emit("vinculada");
            }
          });
        })
        .catch(res => {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.error.title").toString(),
            text: this.$t("notifications.error.error_title").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        })
        .finally(() => {
          this.$q.loading.hide();
        });
    }
  }
  private volver() {
    this.$emit("volver");
  }
}
