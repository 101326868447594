














































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Vecino de Ñuñoa",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Conoce las novedades de la Tarjeta Vecino de Ñuñoa."
      },
      keywords: { name: "keywords", content: "Ñuñoa" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class Home extends Vue {
  private win = window;
  private base = window.location;
  private bannerSlide = "1";
  private categorias: Array<any> = [];
  private horizontalPercentageInteres = 0;

  private mounted() {
    this.getCategorias();
  }

  private getCategorias() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/listado/categorias")
      .then((res: any) => {
        this.categorias = res.data;
        this.categorias.map(function(item) {
          if (item.activo == 1) {
            return item;
          }
        });
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
      });
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Comercio" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Comercio",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }
  private scroll(direccion: string) {
    const size = (this.$refs.scrollInteres as any).scrollSize;
    const position = (this.$refs.scrollInteres as any).scrollPosition;

    if (direccion == "derecha") {
      if (this.horizontalPercentageInteres >= 1) {
        (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position + 316,
          300
        );
      }
    } else {
      if (position - 316 < 0) {
        if (position > 0) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(size, 300);
        }
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position - 316,
          300
        );
      }
    }
  }
}
