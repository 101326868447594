
































































import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
import { Notify } from "quasar";
import axios from "axios";
import FormValidator from "@/components/mixins/FormValidator.ts";
@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Vecino de Ñuñoa",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Revisa el estado de tu Tarjeta Vecino Ñuñoa."
      },
      keywords: { name: "keywords", content: "Ñuñoa" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class Validar extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private formattedRut: string | null = "";
  private data: any = null;
  protected formData = {
    rut: ""
  };

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "");
    this.formData.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  private validate() {
    if (!this.formData.rut || !this.validateRut(this.formData.rut)) {
      this.$swal({
        icon: "error",
        title: "Consulta",
        text: "Por favor, ingrese un Rut válido.",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("revisar?rut=" + this.formData.rut.toUpperCase())
      .then((res: any) => {
        this.data = res.data;
        if (this.data === "bloqueado") {
          this.$swal({
            icon: "error",
            title: "Consulta",
            text: "El rut ingresado se encuentra bloqueado.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "nuevo") {
          this.$swal({
            icon: "warning",
            title: "Consulta",
            text:
              "El rut ingresado no se encuentra registrado en Tarjeta Vecino Ñuñoa.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "tarjeta_anulada") {
          this.$swal({
            icon: "error",
            title: "Consulta",
            text: "La tarjeta asociada al rut se encuentra anulada.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "tarjeta_bloqueada") {
          this.$swal({
            icon: "error",
            title: "Consulta",
            text: "La tarjeta asociada al rut se encuentra bloqueada.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "vencido") {
          this.$swal({
            icon: "warning",
            title: "Consulta",
            text:
              "La tarjeta asociada al rut se encuentra no vigente y debe realizar la renovación.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "vencido_periodo") {
          this.$swal({
            icon: "warning",
            title: "Consulta",
            text:
              "La tarjeta asociada al rut se encuentra vigente, pero puede realizar la renovación.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "renovacion") {
          this.$swal({
            icon: "warning",
            title: "Consulta",
            text:
              "La tarjeta asociada al rut se encuentra no vigente y debe realizar la renovación.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "vigente") {
          this.$swal({
            icon: "success",
            title: "Consulta",
            text: "La tarjeta asociada al rut se encuentra vigente.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "sin_tarjeta") {
          this.$swal({
            icon: "error",
            title: "Consulta",
            text: "El rut ingresado no cuenta con una tarjeta registrada.",
            confirmButtonText: "Aceptar"
          });
        } else if (this.data === "para_renovar") {
          this.$swal({
            icon: "warning",
            title: "Consulta",
            text:
              "La tarjeta asociada al rut se encuentra vigente pero debe realizar la renovación antes del 31 de Diciembre del 2022.",
            confirmButtonText: "Aceptar"
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Consulta",
            text: "Ha ocurrido un error, inténtelo nuevamente.",
            confirmButtonText: "Aceptar"
          });
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        const data = err.response.data;
        this.$swal({
          icon: "error",
          title: "Consulta",
          text: "Ha ocurrido un error, inténtelo nuevamente.",
          confirmButtonText: "Aceptar"
        });
      });
  }

  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
