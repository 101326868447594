

























































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import DataCard from "@/components/Common/DataCard.vue";

@Component({
  components: {
    DataCard
  }
})
export default class EditarPerfilComponent extends Mixins(FormValidator) {
  private userData = {
    username: this.user.username,
    phone: this.user.phone,
    email: this.user.email
  };

  private mounted() {
    if (this.$store.state.user && this.$store.state.user) {
      this.userData = {
        username: this.$store.state.user.username,
        phone: this.$store.state.user.phone,
        email: this.$store.state.user.email
      };
    }
  }
  get user() {
    return this.$store.state.user;
  }

  private updateData() {
    if (this.userData.username == "") {
      this.$swal({
        icon: "error",
        title: this.$t("buttons.update-data").toString(),
        text: this.$t("notifications.error.NO_NAME").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.userData.phone || this.userData.phone == "") {
      this.$swal({
        icon: "error",
        title: this.$t("buttons.update-data").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.userData.phone.length <= 8) {
      this.$swal({
        icon: "error",
        title: this.$t("buttons.update-data").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.userData.email == "") {
      this.$swal({
        icon: "error",
        title: this.$t("buttons.update-data").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.validEmail(this.userData.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("buttons.update-data").toString(),
        text: this.$t("messages.invalid_email").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .put("user/edit", {
        username: this.userData.username.toUpperCase(),
        telefono: this.userData.phone,
        email: this.userData.email.toUpperCase()
      })
      .then(res => {
        this.$q.loading.hide();
        this.$store.commit("SET_USER", this.userData);
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$router.push({ name: "ApplicationsList" });
      })
      .catch(error => {
        this.$q.loading.hide();
        const message = error.response.data.message;
        if (message === "REGISTERED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_NAME") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_NAME").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "telefono invalido") {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("buttons.update-data").toString(),
            text: this.$t("notifications.error.UPDATE_DATA").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
      });
  }

  private formatRut(rut: string) {
    rut = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut
      .substring(0, rut.length - 1)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`.toUpperCase();
  }
  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
}
