






import { Component, Vue } from "vue-property-decorator";
import Contacto from "@/components/Contacto/Contacto.vue";

@Component({
  components: {
    Contacto
  }
})
export default class ContactoView extends Vue {}
