






import { Component, Vue } from "vue-property-decorator";
import Validar from "@/components/Validar/Validar.vue";

@Component({
  components: {
    Validar
  }
})
export default class ValidarView extends Vue {}
