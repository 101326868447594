








































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";
import axios from "axios";
@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Vecino de Ñuñoa",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Conoce las novedades de la Tarjeta Vecino de Ñuñoa."
      },
      keywords: { name: "keywords", content: "Ñuñoa" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class Home extends Vue {
  private win = window;
  private base = window.location;
  private bannerSlide = "1";
  private horizontalPercentageInteres = 0;
  private comercios: Array<any> = [];
  private nombreWeb: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoComercios();
    }
  }

  private irBeneficio(idComercio: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Beneficio" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Beneficio",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private getInfoComercios() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/listado/categoria?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.comercios = res.data;
        for (let i = 0; i < this.comercios.length; i++) {
          if (this.comercios[i].foto != null) {
            this.comercios[i].foto =
              " https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.nunoa/" +
              this.comercios[i].foto;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
      });
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Comercio" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Comercio",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private formatNombreWeb(nombre: any) {
    if (nombre && nombre != "") {
      var data = nombre;
      data = data.replace(" ", "-");
      console.log(data);
      return data;
    }
  }

  private irGoogleMaps(data: any) {
    window.open("https://www.google.com/maps/search/" + data);
  }

  private irWeb(data: any) {
    console.log(data);
    window.open(data);
  }

  private irEmail(mail: string) {
    window.open("mailto:" + mail);
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }

  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }
  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }
  private scroll(direccion: string) {
    const size = (this.$refs.scrollInteres as any).scrollSize;
    const position = (this.$refs.scrollInteres as any).scrollPosition;

    if (direccion == "derecha") {
      if (this.horizontalPercentageInteres >= 1) {
        (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position + 316,
          300
        );
      }
    } else {
      if (position - 316 < 0) {
        if (position > 0) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(size, 300);
        }
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position - 316,
          300
        );
      }
    }
  }
}
