






import { Component, Vue } from "vue-property-decorator";
import Beneficios from "@/components/Beneficios/Beneficios.vue";

@Component({
  components: {
    Beneficios
  }
})
export default class BeneficiosView extends Vue {}
