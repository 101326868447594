






import { Component, Vue } from "vue-property-decorator";
import InscripcionBeneficio from "@/components/Validar/InscripcionBeneficio.vue";

@Component({
  components: {
    InscripcionBeneficio
  }
})
export default class InscripcionBeneficioView extends Vue {}
